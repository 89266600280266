<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 py-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-1" />
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container>
            <v-row class="pr-4">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplyed"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="closeOpenFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- Proveedor -->
                  <v-col cols="12" md="6" sm="12" class="py-0">
                    <v-autocomplete
                      ref="proveedores-autocomplete"
                      v-model="proveedorSelected"
                      :items="proveedores"
                      :search-input.sync="proveedorAutocompleteInput"
                      item-text="value1"
                      item-value="id"
                      :filter="customFilterProveedores"
                      outlined
                      clearable
                      return-object
                      dense
                      autocomplete="off"
                      label="Proveedor"
                      hint="Nombre o código"
                      persistent-hint
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value1
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >Código: {{ item.value2 }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="proveedoresLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Tipos proveedor -->
                  <v-col cols="12" md="5" sm="12" class="py-0">
                    <v-autocomplete
                      v-model="tipoProveedorSelected"
                      :items="tiposProveedores"
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      return-object
                      dense
                      autocomplete="off"
                      label="Tipo proveedor"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Fecha desde -->
                  <v-col cols="12" sm="12" md="3" class="py-0">
                    <v-menu
                      ref="menuFechaDesde"
                      v-model="menuFechaDesde"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          autocomplete="not"
                          hint="Formato DD/MM/AAAA"
                          clearable
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          outlined
                          dense
                          v-on="on"
                          :rules="[
                            new Date(parseDateToIso(fechaDesdeSelected)) <=
                              new Date(parseDateToIso(fechaHastaSelected)) ||
                              'Formato incorrecto'
                          ]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menuFechaDesde = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Fecha hasta -->
                  <v-col cols="12" sm="12" md="3" class="py-0">
                    <v-menu
                      v-model="menuFechaHasta"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          clearable
                          outlined
                          autocomplete="not"
                          dense
                          v-bind="attrs"
                          v-on="on"
                          :rules="[
                            new Date(parseDateToIso(fechaDesdeSelected)) <=
                              new Date(parseDateToIso(fechaHastaSelected)) ||
                              'Formato incorrecto'
                          ]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menuFechaHasta = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Tipo comprobante -->
                  <v-col cols="12" sm="12" md="6" class="py-0">
                    <v-autocomplete
                      v-model="tipoComprobanteSelected"
                      :items="tiposComprobantes"
                      return-object
                      label="Tipo de comprobante"
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Código desde -->
                  <v-col cols="12" sm="12" md="3" class="py-0">
                    <v-text-field
                      v-model.trim="codigoDesde"
                      label="Número desde"
                      outlined
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      clearable
                      dense
                      :rules="
                        (codigoDesde != null && codigoDesde != '') ||
                        (codigoHasta != null && codigoHasta != '')
                          ? rules.requiredAcceptZero.concat(
                              rules.positiveNumber,
                              [rules.maxLength(codigoDesde, 8)],
                              parseInt(codigoDesde) <= parseInt(codigoHasta) ||
                                'Rango inválido'
                            )
                          : []
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Código hasta -->
                  <v-col cols="12" sm="12" md="3" class="py-0">
                    <v-text-field
                      v-model.trim="codigoHasta"
                      label="Número hasta"
                      outlined
                      clearable
                      dense
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      :rules="
                        codigoDesde != null && codigoDesde != ''
                          ? rules.requiredAcceptZero.concat(
                              rules.positiveNumber,
                              [rules.maxLength(codigoHasta, 8)],
                              Number(codigoDesde) <= Number(codigoHasta) ||
                                'Rango inválido'
                            )
                          : []
                      "
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Estado -->
                  <v-col cols="12" sm="12" md="2" class="py-0">
                    <v-autocomplete
                      v-model="estadoSelected"
                      :items="estados"
                      label="Estado"
                      item-text="value"
                      item-value="id"
                      return-object
                      outlined
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    elevation="2"
                    small
                    :disabled="!isFormValid"
                    type="submit"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemsProcesosMasivosOP"
            item-key="nroProceso"
            :loading="loadingTable"
            class="elevation-1"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="9">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canSeeDetalle">
                <template v-slot:activator="{ on, attrs }">
                  <section style="display: inline" v-on="on">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="opemModalDetalleProcMasivoOP(item)"
                    >
                      {{ seeIcon }}
                    </v-icon>
                  </section>
                </template>
                <span>Ver detalle del proceso</span>
              </v-tooltip>
              <v-tooltip left v-if="canAnular">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="anularProceso(item.nroProceso)"
                    :disabled="item.usuarioAnula != null"
                  >
                    {{ anularIcon }}
                  </v-icon>
                </template>
                <span v-if="item.usuarioAnula != null"
                  >El proceso ya sido anulado</span
                >
                <span v-else>Anular proceso</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <DeleteDialog
            :titleProp="titleDelete"
            :maxWidth="'35%'"
            :confirmButtonText="'Anular'"
            :isLoading="loadingAnular"
            :openDelete.sync="showAnularModal"
            @onDeleteItem="confirmAnular()"
          />
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-5 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="modalDetalleProcMasivoOP"
      v-model="modalDetalleProcMasivoOP"
      @keydown.esc="closeModalDetalleProcMasivoOP()"
      max-width="1100px"
      persistent
    >
      <VerDetalleProcesoMasivoOP
        :detalleProcesoMasivoOP="detalleProcesoMasivoOP"
        :canReenviar="canReenviar"
        :anulado="itemAnulado"
        @closeModal="closeModalDetalleProcMasivoOP()"
      ></VerDetalleProcesoMasivoOP>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import FiltersSelected from "@/components/shared/FiltersSelected";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import Ayuda from "@/components/shared/Ayuda.vue";
import VerDetalleProcesoMasivoOP from "@/components/modules/proveedores/ordenesPago/VerDetalleProcesoMasivoOP.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "ConsultaProcesosMasivosOP",
  components: {
    PageHeader,
    FiltersSelected,
    DeleteDialog,
    Ayuda,
    GoBackBtn,
    VerDetalleProcesoMasivoOP
  },
  created() {
    this.setSelects();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  data() {
    return {
      title: "Consulta de procesos de email masivo de órdenes de pago",
      search: "",
      routeToGo: "OrdenesDePago",
      showExpand: false,
      showHelp: false,
      optionCode: enums.webSiteOptions.CONSULTA_PROCESOS_MASIVOS_OP,
      showIcon: true,
      deleteIcon: enums.icons.DELETE,
      searchIcon: enums.icons.SEARCH,
      seeIcon: enums.icons.SEE,
      showFilters: true,
      isFormValid: true,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      calendarIcon: enums.icons.CALENDAR,
      rules: rules,
      allowedActions: null,
      filtersApplyed: [],
      headers: [
        {
          text: "N° de proceso",
          align: "start",
          sortable: false,
          value: "nroProceso"
        },
        {
          text: "Estado",
          sortable: false,
          value: "estado"
        },
        {
          text: "Fecha alta",
          sortable: false,
          value: "fecha"
        },
        {
          text: "Usuario alta",
          sortable: false,
          value: "usuario"
        },
        {
          text: "Fecha anula",
          sortable: false,
          value: "fechaAnula"
        },
        {
          text: "Usuario anula",
          sortable: false,
          value: "usuarioAnula"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "end",
          width: "5%"
        }
      ],
      itemsProcesosMasivosOP: [],
      loadingTable: false,
      titleDelete: "¿Desea anular el proceso?",
      showAnularModal: false,
      menuFechaDesde: false,
      menuFechaHasta: false,
      fechaHastaSelected: null,
      fechaDesdeSelected: null,
      fechaDesde: null,
      fechaHasta: null,
      proveedorAutocompleteInput: null,
      proveedoresLoading: null,
      proveedores: [],
      proveedorSelected: null,
      tipoComprobanteSelected: null,
      tiposComprobantes: [],
      codigoDesde: null,
      codigoHasta: null,
      estadoSelected: { id: -1, value: "Todos" },
      estados: [
        { id: -1, value: "Todos" },
        { id: 0, value: "No enviados" },
        { id: 1, value: "Enviados" }
      ],
      anularIcon: enums.icons.CIRCLE_NONE,
      loadingAnular: false,
      modalDetalleProcMasivoOP: false,
      detalleProcesoMasivoOP: null,
      canSeeDetalle: false,
      canAnular: false,
      canReenviar: false,
      itemAnulado: false,
      tipoProveedorSelected: null,
      tiposProveedores: []
    };
  },
  watch: {
    proveedorAutocompleteInput(val) {
      if (val && val.length >= 3) {
        this.getProveedoresFilter();
      }
    }
  },
  methods: {
    ...mapActions({
      getProcesoEnvioEmailMasivoOP: "proveedores/getProcesoEnvioEmailMasivoOP",
      anularProcesoEnvioEmailMasivoOP:
        "proveedores/anularProcesoEnvioEmailMasivoOP",
      fetchProveedoresByQuery: "proveedores/fetchProveedoresByQuery",
      fetchTiposComprobantesOP: "proveedores/fetchTiposComprobantesOP",
      fetchTiposProveedores: "proveedores/fetchTiposProveedores",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .VER_DETALLE_PROCESO_MASIVO_OP:
            this.canSeeDetalle = true;
            break;
          case enums.modules.adminSistema.permissions.ANULAR_PROCESO_MASIVO_OP:
            this.canAnular = true;
            break;
          case enums.modules.adminSistema.permissions
            .REENVIAR_PROCESO_MASIVO_OP:
            this.canReenviar = true;
            break;
        }
      });
    },
    async setSelects() {
      const tipoComprobante = await this.fetchTiposComprobantesOP();
      this.tiposComprobantes = tipoComprobante;
      const tiposProveedores = await this.fetchTiposProveedores();
      this.tiposProveedores = tiposProveedores;
    },
    async applyFilters() {
      this.loadingTable = true;
      this.customizeFiltersApplied();
      const filters = {
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        provId: this.proveedorSelected?.id,
        numeroDesde: this.codigoDesde,
        numeroHasta: this.codigoHasta,
        estado: this.estadoSelected.id,
        tipoProvId: this.tipoProveedorSelected?.id,
        tCompId: this.tipoComprobanteSelected?.id
      };
      try {
        const response = await this.getProcesoEnvioEmailMasivoOP(filters);
        this.itemsProcesosMasivosOP = response;
        if (this.itemsProcesosMasivosOP.length > 0) {
          this.showFilters = false;
          this.showFiltersSelected = true;
        }
        this.loadingTable = false;
      } catch {
        this.loadingTable = false;
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplyed = [];
      if (this.proveedorSelected) {
        this.filtersApplyed.splice(0, 1, {
          key: "proveedor",
          label: "Proveedor",
          model: this.proveedorSelected.value1
        });
      }
      if (this.tipoProveedorSelected) {
        this.filtersApplyed.splice(1, 1, {
          key: "tipoProv",
          label: "Tipo proveedor",
          model: this.tipoProveedorSelected.value
        });
      }
      if (this.fechaDesdeSelected) {
        this.filtersApplyed.splice(2, 1, {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.fechaDesdeSelected
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplyed.splice(3, 1, {
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.fechaHastaSelected
        });
      }
      if (this.tipoComprobanteSelected) {
        this.filtersApplyed.splice(4, 1, {
          key: "tipoComp",
          label: "Tipo de comprobante",
          model: this.tipoComprobanteSelected.value
        });
      }
      if (this.codigoDesde) {
        this.filtersApplyed.splice(5, 1, {
          key: "codigoDesde",
          label: "Número desde",
          model: this.codigoDesde
        });
      }
      if (this.codigoHasta) {
        this.filtersApplyed.splice(6, 1, {
          key: "codigoHasta",
          label: "Número hasta",
          model: this.codigoHasta
        });
      }
      if (this.estadoSelected) {
        this.filtersApplyed.splice(7, 1, {
          key: "estado",
          label: "Estado",
          model: this.estadoSelected.value
        });
      }
    },
    closeOpenFilters() {
      this.showFiltersSelected = !this.showFiltersSelected;
      this.showFilters = !this.showFilters;
    },
    anularProceso(id) {
      this.showAnularModal = true;
      this.idToAnular = id;
    },
    async confirmAnular() {
      this.loadingAnular = true;
      try {
        const response = await this.anularProcesoEnvioEmailMasivoOP(
          this.idToAnular
        );
        if (response.status === 200) {
          this.loadingAnular = false;
          this.showAnularModal = false;
          this.setAlert({
            type: "success",
            message: "El proceso ha sido anulado."
          });
          this.applyFilters();
        }
      } catch (error) {
        this.showAnularModal = false;
      }
    },
    getProveedoresFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.proveedores?.some(
            x =>
              x.value2 == this.proveedorAutocompleteInput ||
              x.value1 == this.proveedorAutocompleteInput
          )
        ) {
          return;
        } else {
          this.proveedoresLoading = true;
          const response = await this.fetchProveedoresByQuery({
            input: this.proveedorAutocompleteInput
          });
          this.proveedores = response;
          this.proveedoresLoading = false;
        }
      }, 1000);
    },
    customFilterProveedores(item, queryText, itemText) {
      return item.value1 || item.value2.indexOf(queryText) > -1;
    },
    closeModalDetalleProcMasivoOP() {
      this.modalDetalleProcMasivoOP = false;
      this.applyFilters();
    },
    opemModalDetalleProcMasivoOP(item) {
      this.modalDetalleProcMasivoOP = true;
      this.itemAnulado = item.usuarioAnula != null ? true : false;
      this.detalleProcesoMasivoOP = item;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped></style>
