var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0 py-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{staticClass:"pb-1",attrs:{"title":_vm.title}}),_c('v-card',{staticClass:"mx-auto my-12 mt-2 mb-2",attrs:{"max-width":"1200","outlined":""}},[_c('v-container',[_c('v-row',{staticClass:"pr-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"10","align":"left"},on:{"click":function($event){_vm.showFilters = true}}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplyed}}):_vm._e()],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"2","align":"right","align-self":"center"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();return _vm.closeOpenFilters($event)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-expand-transition',[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilters()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-autocomplete',{ref:"proveedores-autocomplete",attrs:{"items":_vm.proveedores,"search-input":_vm.proveedorAutocompleteInput,"item-text":"value1","item-value":"id","filter":_vm.customFilterProveedores,"outlined":"","clearable":"","return-object":"","dense":"","autocomplete":"off","label":"Proveedor","hint":"Nombre o código","persistent-hint":""},on:{"update:searchInput":function($event){_vm.proveedorAutocompleteInput=$event},"update:search-input":function($event){_vm.proveedorAutocompleteInput=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.value1))]),_c('v-list-item-subtitle',[_vm._v("Código: "+_vm._s(item.value2)+" ")])],1)]}},{key:"append",fn:function(){return [(_vm.proveedoresLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"28","color":"primary"}}):_vm._e()]},proxy:true}]),model:{value:(_vm.proveedorSelected),callback:function ($$v) {_vm.proveedorSelected=$$v},expression:"proveedorSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"5","sm":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.tiposProveedores,"item-text":"value","item-value":"id","outlined":"","clearable":"","return-object":"","dense":"","autocomplete":"off","label":"Tipo proveedor"},model:{value:(_vm.tipoProveedorSelected),callback:function ($$v) {_vm.tipoProveedorSelected=$$v},expression:"tipoProveedorSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-menu',{ref:"menuFechaDesde",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha desde","append-icon":_vm.calendarIcon,"autocomplete":"not","hint":"Formato DD/MM/AAAA","clearable":"","outlined":"","dense":"","rules":[
                          new Date(_vm.parseDateToIso(_vm.fechaDesdeSelected)) <=
                            new Date(_vm.parseDateToIso(_vm.fechaHastaSelected)) ||
                            'Formato incorrecto'
                        ]},on:{"blur":function($event){_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeSelected)}},model:{value:(_vm.fechaDesdeSelected),callback:function ($$v) {_vm.fechaDesdeSelected=$$v},expression:"fechaDesdeSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaDesde),callback:function ($$v) {_vm.menuFechaDesde=$$v},expression:"menuFechaDesde"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaDesdeSelected = _vm.formatDate(_vm.fechaDesde)},"input":function($event){_vm.menuFechaDesde = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha hasta","append-icon":_vm.calendarIcon,"hint":"Formato DD/MM/AAAA","clearable":"","outlined":"","autocomplete":"not","dense":"","rules":[
                          new Date(_vm.parseDateToIso(_vm.fechaDesdeSelected)) <=
                            new Date(_vm.parseDateToIso(_vm.fechaHastaSelected)) ||
                            'Formato incorrecto'
                        ]},on:{"blur":function($event){_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaSelected)}},model:{value:(_vm.fechaHastaSelected),callback:function ($$v) {_vm.fechaHastaSelected=$$v},expression:"fechaHastaSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaHasta),callback:function ($$v) {_vm.menuFechaHasta=$$v},expression:"menuFechaHasta"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaHastaSelected = _vm.formatDate(_vm.fechaHasta)},"input":function($event){_vm.menuFechaHasta = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.tiposComprobantes,"return-object":"","label":"Tipo de comprobante","item-text":"value","item-value":"id","outlined":"","clearable":"","dense":""},model:{value:(_vm.tipoComprobanteSelected),callback:function ($$v) {_vm.tipoComprobanteSelected=$$v},expression:"tipoComprobanteSelected"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Número desde","outlined":"","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","clearable":"","dense":"","rules":(_vm.codigoDesde != null && _vm.codigoDesde != '') ||
                      (_vm.codigoHasta != null && _vm.codigoHasta != '')
                        ? _vm.rules.requiredAcceptZero.concat(
                            _vm.rules.positiveNumber,
                            [_vm.rules.maxLength(_vm.codigoDesde, 8)],
                            parseInt(_vm.codigoDesde) <= parseInt(_vm.codigoHasta) ||
                              'Rango inválido'
                          )
                        : []},model:{value:(_vm.codigoDesde),callback:function ($$v) {_vm.codigoDesde=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"codigoDesde"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Número hasta","outlined":"","clearable":"","dense":"","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","rules":_vm.codigoDesde != null && _vm.codigoDesde != ''
                        ? _vm.rules.requiredAcceptZero.concat(
                            _vm.rules.positiveNumber,
                            [_vm.rules.maxLength(_vm.codigoHasta, 8)],
                            Number(_vm.codigoDesde) <= Number(_vm.codigoHasta) ||
                              'Rango inválido'
                          )
                        : []},model:{value:(_vm.codigoHasta),callback:function ($$v) {_vm.codigoHasta=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"codigoHasta"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.estados,"label":"Estado","item-text":"value","item-value":"id","return-object":"","outlined":"","dense":""},model:{value:(_vm.estadoSelected),callback:function ($$v) {_vm.estadoSelected=$$v},expression:"estadoSelected"}})],1)],1),_c('v-row',{staticClass:"pr-4 pl-4",attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","elevation":"2","small":"","disabled":!_vm.isFormValid,"type":"submit","form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsProcesosMasivosOP,"item-key":"nroProceso","loading":_vm.loadingTable,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [(_vm.canSeeDetalle)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('section',_vm._g({staticStyle:{"display":"inline"}},on),[_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.opemModalDetalleProcMasivoOP(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.seeIcon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalle del proceso")])]):_vm._e(),(_vm.canAnular)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20","disabled":item.usuarioAnula != null},on:{"click":function($event){return _vm.anularProceso(item.nroProceso)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.anularIcon)+" ")])]}}],null,true)},[(item.usuarioAnula != null)?_c('span',[_vm._v("El proceso ya sido anulado")]):_c('span',[_vm._v("Anular proceso")])]):_vm._e()]}}],null,true)}),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"maxWidth":'35%',"confirmButtonText":'Anular',"isLoading":_vm.loadingAnular,"openDelete":_vm.showAnularModal},on:{"update:openDelete":function($event){_vm.showAnularModal=$event},"update:open-delete":function($event){_vm.showAnularModal=$event},"onDeleteItem":function($event){return _vm.confirmAnular()}}})],1)],1),(_vm.showIcon)?_c('v-col',{staticClass:"text-right pt-5 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":_vm.toggleHelp,"toggleIcon":function($event){return _vm.toggleIcon()}}})],1):_vm._e()],1),(_vm.modalDetalleProcMasivoOP)?_c('v-dialog',{attrs:{"max-width":"1100px","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModalDetalleProcMasivoOP()}},model:{value:(_vm.modalDetalleProcMasivoOP),callback:function ($$v) {_vm.modalDetalleProcMasivoOP=$$v},expression:"modalDetalleProcMasivoOP"}},[_c('VerDetalleProcesoMasivoOP',{attrs:{"detalleProcesoMasivoOP":_vm.detalleProcesoMasivoOP,"canReenviar":_vm.canReenviar,"anulado":_vm.itemAnulado},on:{"closeModal":function($event){return _vm.closeModalDetalleProcMasivoOP()}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }