var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.detalleProcesoItems,"item-key":"procEmailId","loading":_vm.loadingTable,"expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('strong',[_vm._v("Observación:")]),_vm._v(" "+_vm._s(item.observaciones)+" ")])]}},{key:"item.provNombre",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.estado == 'Error' ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.provNombre))])]}},{key:"item.comprobante",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.estado == 'Error' ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.comprobante))])]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.estado == 'Error' ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.fecha))])]}},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.estado == 'Error' ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.estado))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9","align":"start"}},[_c('v-card-title',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.title)+" ")])],1),_c('v-col',{staticClass:"pt-5",attrs:{"cols":"2","align":"end"}},[(_vm.canReenviar)?_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary","loading":_vm.loadingReenviarBtn,"disabled":_vm.anulado || _vm.errorCount === 0},on:{"click":function($event){return _vm.reenviarProceso()}}},[_vm._v(" Reenviar ")]):_vm._e()],1),(_vm.canReenviar && !_vm.anulado && _vm.errorCount !== 0)?_c('v-col',{staticClass:"pt-6",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text"},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon)+" ")])]}}],null,false,1031365674)},[_c('span',[_vm._v("Se reenviarán solo los registros con error.")])])],1):_vm._e()],1)],1)]},proxy:true}],null,true)}),_c('v-card-actions',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"11"}},[_c('v-tooltip',{attrs:{"left":"","max-width":"40%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"to-right",attrs:{"color":"primary","outlined":"","disabled":_vm.detalleProcesoItems.length == 0},on:{"click":_vm.exportExcelModelo}},'v-btn',attrs,false),[_vm._v(" Exportar detalle ")])]}}])})],1),_c('v-col',{staticClass:"py-0 pr-2",attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"to-right",attrs:{"outlined":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cerrar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }