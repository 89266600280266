<template>
  <v-card>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="detalleProcesoItems"
        class="elevation-1"
        item-key="procEmailId"
        :loading="loadingTable"
        :expanded.sync="expanded"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong>Observación:</strong>
            {{ item.observaciones }}
          </td>
        </template>
        <template v-slot:[`item.provNombre`]="{ item }">
          <span :style="item.estado == 'Error' ? 'color:red' : ''">
            {{ item.provNombre }}</span
          >
        </template>
        <template v-slot:[`item.comprobante`]="{ item }">
          <span :style="item.estado == 'Error' ? 'color:red' : ''">
            {{ item.comprobante }}</span
          >
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          <span :style="item.estado == 'Error' ? 'color:red' : ''">
            {{ item.fecha }}</span
          >
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <span :style="item.estado == 'Error' ? 'color:red' : ''">
            {{ item.estado }}</span
          >
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="9" align="start">
                <v-card-title class="pl-1 primary--text"
                  >{{ title }}
                </v-card-title>
              </v-col>
              <v-col cols="2" align="end" class="pt-5">
                <v-btn
                  color="primary"
                  class="to-right"
                  v-if="canReenviar"
                  :loading="loadingReenviarBtn"
                  @click="reenviarProceso()"
                  :disabled="anulado || errorCount === 0"
                >
                  Reenviar
                </v-btn>
              </v-col>
              <v-col
                cols="1"
                class="pt-6"
                v-if="canReenviar && !anulado && errorCount !== 0"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="primary--text" v-bind="attrs" v-on="on">
                      {{ infoIcon }}
                    </v-icon>
                  </template>
                  <span>Se reenviarán solo los registros con error.</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-row>
          <v-col cols="11" class="py-0">
            <v-tooltip left max-width="40%">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  class="to-right"
                  v-bind="attrs"
                  outlined
                  :disabled="detalleProcesoItems.length == 0"
                  @click="exportExcelModelo"
                >
                  Exportar detalle
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
          <v-col cols="1" class="py-0 pr-2">
            <v-btn outlined @click="closeModal()" class="to-right">
              Cerrar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import helpersExcel from "@/utils/helpers/importExportExcel";
import enums from "@/utils/enums/index.js";

export default {
  name: "VerDetalleProcesoMasivoOP",
  props: {
    detalleProcesoMasivoOP: {
      Type: Object,
      required: true,
    },
    canReenviar: {
      Type: Boolean,
      required: true,
    },
    anulado: {
      Type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: `Detalle del proceso de envío masivo N° ${this.detalleProcesoMasivoOP.nroProceso}`,
      detalleProcesoItems: [],
      expanded: [],
      infoIcon: enums.icons.SNB_INFO,
      headers: [
        {
          text: "Nombre proveedor",
          align: "start",
          sortable: false,
          value: "provNombre",
        },
        {
          text: "Comprobante",
          sortable: false,
          value: "comprobante",
        },
        {
          text: "Fecha y hora",
          sortable: false,
          value: "fecha",
        },
        {
          text: "Estado",
          sortable: false,
          value: "estado",
        },
       
      ],
      loadingTable: false,
      loadingReenviarBtn: false,
      errorCount: 0,
    };
  },
  created() {
    this.setDetalleProceso();
    this.detalleProcesoItems.map((item) => {
      if (item.estado == "Error") this.errorCount += 1;
    });
  },
  methods: {
    ...mapActions({
      reenviarProcesoEnvioEmailMasivoOP:
        "proveedores/reenviarProcesoEnvioEmailMasivoOP",
      setAlert: "user/setAlert",
    }),
    closeModal() {
      this.$emit("closeModal");
    },
    async setDetalleProceso() {
      this.loadingTable = true;
      this.detalleProcesoItems = this.detalleProcesoMasivoOP.detalle;
      let tieneObservacion  = this.detalleProcesoItems.filter(x => x.observaciones != null)
      this.expanded = tieneObservacion
      this.loadingTable = false;
    },
    async reenviarProceso() {
      this.loadingReenviarBtn = true;
      try {
        const response = await this.reenviarProcesoEnvioEmailMasivoOP(
          this.detalleProcesoMasivoOP.nroProceso
        );
        if (response.status === 200) {
          this.setAlert({
            type: "success",
            message: "Reenviado con éxito.",
          });
          this.closeModal();
        }
      } catch (error) {
        this.loadingReenviarBtn = false;
      }
    },
    exportExcelModelo() {
      let result = [];
      this.detalleProcesoItems.forEach((x) =>
        result.push({
          ["Proveedor"]: x.provNombre,
          ["Comprobante"]: x.comprobante,
          ["Email destinatario"]: x.emailDestinatario,
          ["Estado"]: x.estado,
          ["Fecha y hora de envío"]: x.fecha,
          ["Observación"]: x.observaciones,
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(
        formato,
        "Detalle del proceso de bajas automáticas"
      );
    },
  },
};
</script>

<style scoped></style>
